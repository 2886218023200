var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reportPrint" },
    [
      _c(
        "Row",
        { staticStyle: { height: "100%" } },
        [
          _vm.expand
            ? _c(
                "Col",
                { staticStyle: { "max-height": "100%" }, style: _vm.leftSpan },
                [
                  _c(
                    "Card",
                    { staticStyle: { height: "100%", overflow: "hidden" } },
                    [
                      _c("Alert", { staticClass: "alert" }, [
                        _vm._v("人员查询"),
                      ]),
                      _c(
                        "Row",
                        [
                          _c(
                            "RadioGroup",
                            {
                              on: { "on-change": _vm.statusChange },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "未审" } }),
                              _c("Radio", { attrs: { label: "已审" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("Row", {}, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              "text-align": "right",
                            },
                          },
                          [
                            _c(
                              "Checkbox",
                              {
                                staticStyle: { "margin-right": "156px" },
                                attrs: {
                                  indeterminate: _vm.indeterminate,
                                  value: _vm.checkAll,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleCheckAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("全选 ")]
                            ),
                            _c(
                              "i-switch",
                              {
                                staticStyle: { "text-align": "center" },
                                attrs: { size: "large" },
                                on: { "on-change": _vm.typeChange },
                                model: {
                                  value: _vm.typeStatus,
                                  callback: function ($$v) {
                                    _vm.typeStatus = $$v
                                  },
                                  expression: "typeStatus",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "open" }, slot: "open" },
                                  [_vm._v("复查")]
                                ),
                                _c(
                                  "span",
                                  { attrs: { slot: "close" }, slot: "close" },
                                  [_vm._v("非复查")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.personData && _vm.personData.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _vm.isShowButton &&
                                _vm.personData &&
                                _vm.personData.length > 0
                                  ? _c(
                                      "Button",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          padding: "0 5px",
                                          "line-height": "19px !important",
                                        },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          style:
                                            "height: 20px;padding: 0 5px;line-height: 19px !important;",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadTemplate()
                                          },
                                        },
                                      },
                                      [_vm._v("下载Word")]
                                    )
                                  : _vm._e(),
                                _vm.isShowButton && _vm.personData.length > 0
                                  ? _c(
                                      "Button",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          padding: "0 5px",
                                          "line-height": "19px !important",
                                        },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          style:
                                            "height: 20px;padding: 0 5px;line-height: 19px !important;",
                                        },
                                        on: { click: _vm.batchPrintSelect },
                                      },
                                      [_vm._v("批量打印")]
                                    )
                                  : _vm._e(),
                                _vm.isShowButton && _vm.personData.length > 0
                                  ? _c(
                                      "Button",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          padding: "0 5px",
                                          "line-height": "19px !important",
                                        },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          style:
                                            "height: 20px;padding: 0 5px;line-height: 19px !important;",
                                        },
                                        on: { click: _vm.confirmPrintSelect },
                                      },
                                      [_vm._v("打印确认")]
                                    )
                                  : _vm._e(),
                                _vm.isShowButton &&
                                _vm.personData &&
                                _vm.personData.length > 0
                                  ? _c(
                                      "Button",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          padding: "0 5px",
                                          "line-height": "19px !important",
                                        },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          style:
                                            "height: 20px;padding: 0 5px;line-height: 19px !important;",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadTemplatePdf()
                                          },
                                        },
                                      },
                                      [_vm._v("批量下载Pdf")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm.queryTime
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "Checkbox",
                                {
                                  attrs: {
                                    indeterminate: _vm.indeterminate,
                                    value: _vm.checkAll,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleCheckAll.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("全选 ")]
                              ),
                              _c(
                                "RadioGroup",
                                {
                                  staticClass: "btngroup",
                                  attrs: {
                                    type: "button",
                                    size: "small",
                                    "button-style": "solid",
                                  },
                                  on: { "on-change": _vm.pick },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                [
                                  _c("Radio", { attrs: { label: "当日" } }),
                                  _c("Radio", { attrs: { label: "当月" } }),
                                  _c(
                                    "DatePicker",
                                    {
                                      attrs: {
                                        open: _vm.datePickerShow,
                                        transfer: "",
                                        type: "daterange",
                                      },
                                      on: { "on-change": _vm.datePickerChange },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          [
                                            _c("Radio", {
                                              attrs: { label: "自定义" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.datePickerShow =
                                                    !_vm.datePickerShow
                                                },
                                              },
                                            }),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c("Radio", { attrs: { label: "重置" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "nowrap",
                                "align-items": "center",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "开始时间",
                                },
                                on: { "on-change": _vm.datePickerStartTime },
                                model: {
                                  value: _vm.searchForm.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "startDate", $$v)
                                  },
                                  expression: "searchForm.startDate",
                                },
                              }),
                              _vm._v("  ~  "),
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "结束时间",
                                },
                                on: { "on-change": _vm.datePickerEndTime },
                                model: {
                                  value: _vm.searchForm.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "endDate", $$v)
                                  },
                                  expression: "searchForm.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                      _c("Input", {
                        staticStyle: {
                          "margin-top": "10px",
                          "margin-bottom": "10px",
                        },
                        attrs: { placeholder: "请输入姓名", clearable: "" },
                        on: {
                          "on-clear": function ($event) {
                            return _vm.inputSearchChange("")
                          },
                        },
                        nativeOn: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.inputSearchChange.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.personName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "personName", $$v)
                          },
                          expression: "searchForm.personName",
                        },
                      }),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入体检编号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.testNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "testNum", $$v)
                              },
                              expression: "searchForm.testNum",
                            },
                          })
                        : _vm._e(),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入单位名称",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.dept,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "dept", $$v)
                              },
                              expression: "searchForm.dept",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.inputSearchChange },
                        },
                        [_vm._v("查询")]
                      ),
                      !_vm.queryTime
                        ? _c(
                            "Button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearchForm("重置")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                          _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        {
                          staticStyle: { "justify-content": "center" },
                          style: { height: "calc(100% - " + _vm.height1 + ")" },
                        },
                        [
                          _vm.personData.length > 0
                            ? _c(
                                "CheckboxGroup",
                                {
                                  staticClass: "radio-group",
                                  on: { "on-change": _vm.peopleChange },
                                  model: {
                                    value: _vm.radioValue,
                                    callback: function ($$v) {
                                      _vm.radioValue = $$v
                                    },
                                    expression: "radioValue",
                                  },
                                },
                                _vm._l(_vm.personData, function (item, index) {
                                  return _c(
                                    "Row",
                                    {
                                      key: index,
                                      staticClass: "row-border",
                                      class: {
                                        active: item.id == _vm.personInfo.id,
                                      },
                                      staticStyle: { "margin-left": "15px" },
                                    },
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-radio",
                                          attrs: { span: 3 },
                                        },
                                        [
                                          _c("Checkbox", {
                                            staticClass: "label",
                                            attrs: { label: item.id },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-content",
                                          attrs: { span: 21 },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.radioChooseClick(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "Row",
                                            [
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                  attrs: { span: "13" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "80px",
                                                        overflow: "hidden",
                                                        "text-overflow":
                                                          "ellipsis",
                                                        "white-space": "nowrap",
                                                      },
                                                      attrs: {
                                                        title: item.personName,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.personName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.sex))]
                                                  ),
                                                  _c("div", [
                                                    _vm._v(_vm._s(item.age)),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 11 } },
                                                [
                                                  _c(
                                                    "Row",
                                                    { attrs: { gutter: 6 } },
                                                    [
                                                      _c(
                                                        "Col",
                                                        { attrs: { span: 9 } },
                                                        [
                                                          item.isPass == 4
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "bg-audit",
                                                                  staticStyle: {
                                                                    padding:
                                                                      "0 5px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 未审 "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "bg-green",
                                                                  staticStyle: {
                                                                    padding:
                                                                      "0 5px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 已审 "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "Col",
                                                        { attrs: { span: 9 } },
                                                        [
                                                          item.printState != 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "bg-audit",
                                                                  staticStyle: {
                                                                    padding:
                                                                      "0 5px",
                                                                    background:
                                                                      "none",
                                                                    color:
                                                                      "#426ab3",
                                                                    border:
                                                                      "1px solid #426ab3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 未打 "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "bg-green",
                                                                  staticStyle: {
                                                                    padding:
                                                                      "0 5px",
                                                                    background:
                                                                      "none",
                                                                    color:
                                                                      "#78a355",
                                                                    border:
                                                                      "1px solid #78a355",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 已打 "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "Col",
                                                        { attrs: { span: 6 } },
                                                        [
                                                          item.sporadicPhysical &&
                                                          item.sporadicPhysical ==
                                                            1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "border-purple",
                                                                },
                                                                [_vm._v(" 零 ")]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "border-purple",
                                                                },
                                                                [_vm._v(" 团 ")]
                                                              ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("no-data"),
                          _vm.checkPersonLoading
                            ? _c(
                                "Spin",
                                { attrs: { fix: "" } },
                                [
                                  _c("Icon", {
                                    staticClass: "demo-spin-icon-load",
                                    attrs: { type: "ios-loading", size: "18" },
                                  }),
                                  _c("div", [_vm._v("加载中...")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.personData.length > 0
                            ? _c("Page", {
                                attrs: {
                                  current: _vm.searchForm.pageNumber,
                                  total: _vm.groupDataTotal,
                                  simple: "",
                                  "show-sizer": "",
                                  "page-size-opts": [10, 20, 50],
                                  "page-size": _vm.searchForm.pageSize,
                                },
                                on: { "on-change": _vm.personChangePageNum },
                              })
                            : _vm._e(),
                          _vm.personData.length > 0
                            ? _c(
                                "Select",
                                {
                                  staticStyle: {
                                    width: "4.8vw",
                                    height: "2.5vh",
                                  },
                                  on: { "on-change": _vm.changePageSize },
                                  model: {
                                    value: _vm.searchForm.pageSize,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "pageSize", $$v)
                                    },
                                    expression: "searchForm.pageSize",
                                  },
                                },
                                _vm._l(_vm.pageSizeList, function (item) {
                                  return _c(
                                    "Option",
                                    {
                                      key: item.value,
                                      attrs: { value: item.value },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            { style: _vm.span },
            [
              _vm.isTemplateShow
                ? _c("auditReport", {
                    ref: "templateShow",
                    staticStyle: { height: "100vh", overflow: "hidden" },
                    attrs: {
                      "type-status": _vm.typeStatus,
                      isReview: _vm.searchForm.isReviewer,
                      personIds: _vm.personIds,
                      isBatchPrint: _vm.isBatchPrint,
                      taskNow: this.taskNow,
                      physicalType: _vm.physicalType,
                      tGroupPerson: _vm.tGroupPerson,
                    },
                    on: { closeLoading: _vm.closeLoading },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }